import { tarifaBaseService } from "@/_services";

export const tarifaBase = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    geos: {
      data: [],
      status: {},
    },
    tarifasAsExcel: {
      data: [],
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getTarifasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getTarifasError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getTarifasSuccess(state, tarifas) {
      state.list.data = tarifas;
      state.list.status = {
        loaded: true,
      };
    },
    getTarifasGeosRequest(state) {
      state.geos.status = {
        loading: true,
      };
    },
    getTarifasGeosError(state, error) {
      state.geos.data = [];
      state.geos.status = {
        error,
      };
    },
    getTarifasGeosSuccess(state, geos) {
      state.geos.data = geos;
      state.geos.status = {
        loaded: true,
      };
    },
    getTarifaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getTarifaByIdError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    getTarifaByIdSuccess(state, tarifa) {
      state.entity.data = tarifa;
      state.entity.status = {
        loaded: true,
      };
    },
    tarifasExcelRequest(state) {
      state.tarifasAsExcel.status = {
        downloading: true,
      };
    },
    tarifasExcelSuccess(state, pdf) {
      state.tarifasAsExcel.data = pdf;
      state.tarifasAsExcel.status = {
        downloaded: true,
      };
    },
    tarifasExcelError(state, error) {
      state.tarifasAsExcel.data = null;
      state.tarifasAsExcel.status = {
        error,
      };
    },
  },
  actions: {
    getTarifasBase({ commit }, req) {
      commit("getTarifasRequest");
      tarifaBaseService
        .getTarifas(req.pagination, req.filters)
        .then((tarifas) => {
          commit("getTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("getTarifasError", error);
        });
    },
    getTarifasGeos({ commit }) {
      commit("getTarifasGeosRequest");
      tarifaBaseService
        .getTarifasGeos()
        .then((res) => {
          commit("getTarifasGeosSuccess", res);
        })
        .catch((error) => {
          commit("getTarifasGeosError", error);
        });
    },
    getTarifaById({ commit }, id) {
      commit("getTarifaByIdRequest");
      tarifaBaseService
        .getTarifaById(id)
        .then((tarifas) => {
          commit("getTarifaByIdSuccess", tarifas);
        })
        .catch((error) => {
          commit("getTarifaByIdError", error);
        });
    },
    getTarifasAsExcel({ commit }, request) {
      commit("tarifasExcelRequest");
      tarifaBaseService
        .getTarifasAsExcel(request)
        .then((excel) => {
          commit("tarifasExcelSuccess", excel);
        })
        .catch((error) => {
          commit("tarifasExcelError", error);
        });
    },
  },
};
