import createAxios from "./axiosHttpMarconi";
export const facturaService = {
  getFacturas,
  downloadFactura,
  getFacturaById,
  getFacturasAsExcel,
  accionFacturar,
  rectificarFactura,
  editFactura,
  accionCancelar,
  downloadContable,
  downloadPreFactura,
};

function getFacturas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/facturas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  } else {
    urlPath += `&sort=fecha&order=descending`;
  }
  if (filters && filters.numeroFactura) {
    urlPath += `&numero=${filters.numeroFactura}`;
  }
  if (filters && filters.fecha[0]) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`;
  }
  if (filters && filters.fecha[1]) {
    urlPath += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters && filters.cliente && filters.cliente._id != "") {
    urlPath += `&idCliente=${filters.cliente._id}`;
  }
  if (localStorage.getItem("agrupacionClienteSelected")) {
    urlPath += `&agrupacion=${localStorage.getItem(
      "agrupacionClienteSelected"
    )}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  return axios.get(urlPath);
}

function downloadFactura(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/factura/${request.facturaId}/download`;
  if (request && request.tipoDocumento) {
    urlPath += `?tipo_documento=${request.tipoDocumento}`;
  }
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function downloadPreFactura(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/pre-factura/${id}/download`;
  return axios
    .get(urlPath, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}
function getFacturaById(idFactura) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/factura/${idFactura}`;
  return axios.get(urlPath);
}
function editFactura(factura) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/factura/${factura._id}`;
  return axios.put(urlPath, factura);
}

function getFacturasAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/facturas/excel?limit=2000`;

  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  } else {
    urlPath += `&sort=fecha&order=descending`;
  }
  if (filters && filters.fecha[0]) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`;
  }
  if (filters && filters.fecha[1]) {
    urlPath += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters && filters.cliente && filters.cliente._id != "") {
    urlPath += `&idCliente=${filters.cliente._id}`;
  }
  if (localStorage.getItem("agrupacionClienteSelected")) {
    urlPath += `&agrupacion=${localStorage.getItem(
      "agrupacionClienteSelected"
    )}`;
  }
  if (filters && filters.numeroFactura) {
    urlPath += `&numero=${filters.numeroFactura}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }
  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function rectificarFactura(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/factura/${request.facturaId}/rectificar`;
  return axios.post(urlPath, request);
}

function accionFacturar(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/facturas/facturar`;
  return axios.put(urlPath, request);
}

function accionCancelar(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/facturas/cancelar`;
  return axios.put(urlPath, request);
}

function downloadContable(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/exportacion_contable`;
  if (body && body.desde) {
    urlPath += `?desde=${body.desde}`;
  }
  if (body && body.hasta) {
    urlPath += `&hasta=${body.hasta}`;
  }
  urlPath += `&tipo=${body.tipo}`;

  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}
