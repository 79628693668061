import axios from "axios";
import createAxios from "./axiosHttpMarconi";
import createAxiosLogin from "./axiosLogin";

export const userService = {
  login,
  changePassword,
  tieneRol,
  esFranquicia,
  logout,
  oneShotToken,
  getUsuarios,
  usersFranquicia,
  getRoles,
  nuevoUsuario,
  cambiarEstadoUser,
  getEnlaceRecuperacion,
  resetPassword,
};

function login(user, password) {
  const axios = createAxiosLogin();
  return axios.post("/auth/login", { user, password }).then((userLogged) => {
    if (userLogged) {
      localStorage.setItem("user", JSON.stringify(userLogged));
    }
    return userLogged;
  });
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("agrupacionClienteSelected");
  localStorage.removeItem("franquicia");
}

function oneShotToken() {
  const axios = createAxios();
  return axios.get("/auth/token");
}

function getRoles(tipo) {
  const axios = createAxios();
  let urlPath = `/auth/roles`;
  if (tipo) {
    urlPath += `?tipo=${tipo}`;
  }
  return axios.get(urlPath);
}

function nuevoUsuario(tipo, id, user) {
  const axios = createAxios();
  let urlPath = `/${tipo}/${id}/auth/user`;
  return axios.post(urlPath, user);
}

function usersFranquicia(id, filters, pagination, tipo) {
  const axios = createAxios();
  let urlPath = `/${tipo}/${id}/auth/user`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.user) {
    urlPath += `&user=${filters.user}`;
  }
  if (filters && filters.roles) {
    urlPath += `&roles=${filters.roles}`;
  }
  if (filters && filters.status) {
    urlPath += `&status=${filters.status}`;
  }
  return axios.get(urlPath);
}

function tieneRol(user, rol) {
  return user.roles != null && user.roles.indexOf(rol) > -1;
}

function cambiarEstadoUser(user) {
  const axios = createAxios();
  const urlPath = `/auth/user/${user}`;
  return axios.put(urlPath);
}

function esFranquicia(user) {
  return tieneRol(user, "FRANQUICIA");
}

function changePassword(body) {
  const axios = createAxios();
  const urlPath = "/auth/password";
  return axios.post(urlPath, body);
}

function getUsuarios(filters, pagination) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/auth/user`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }

  urlPath += getFilters(filters);

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }

  return axios.get(urlPath);
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.status) {
    urlPath += `&status=${filters.status}`;
  }

  if (filters && filters.externalId) {
    urlPath += `&externalId=${filters.externalId}`;
  }

  if (filters && filters.externalProvider) {
    urlPath += `&externalProvider=${filters.externalProvider}`;
  }

  if (filters && filters.user) {
    urlPath += `&user=${filters.user}`;
  }

  if (filters && filters.autocomplete) {
    urlPath += `&autocomplete=${filters.autocomplete}`;
  }
  if (filters && filters.roles && filters.roles.length > 0) {
    urlPath += `&roles=${filters.roles.join()}`;
  }
  return urlPath;
}

function getEnlaceRecuperacion(body) {
  const axios = createAxiosLogin();
  const urlPath = "/auth/password/link";
  return axios.post(urlPath, body);
}

function resetPassword(body, token) {
  //console.log(token);
  const axios = createAxiosLogin();
  const urlPath = `/auth/password/reestablecer?ost=${token}`;
  return axios.post(urlPath, body);
}
