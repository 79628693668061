import { createAxios } from "./axiosHttpMarconi";
export const incidenciaService = {
  getIncidencias,
  getIncidenciaById,
  getIncidenciaChats,
  patchIncidencia,
  postMensaje,
  deleteChatIncidencia,
  putChatIncidencia,
  getAdjunto,
  multipleAsignarIncidencia,
  getIncidenciasAsExcel,
  getSolucionesByIncidencia,
};
function getIncidenciasAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/excel?limit=2000`;

  urlPath += getFiltersIncidencias(filters);

  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getFiltersIncidencias(filters) {
  let urlPath = "";
  if (filters && filters.fecha) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`;
    urlPath += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters && filters.fechaEnvio) {
    urlPath += `&fecha_envio_inicio=${filters.fechaEnvio[0]}`;
    urlPath += `&fecha_envio_fin=${filters.fechaEnvio[1]}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }
  if (filters && filters.numero) {
    urlPath += `&numero=${filters.numero}`;
  }
  if (
    filters &&
    filters.estadoContestacion &&
    filters.estadoContestacion.length > 0
  ) {
    urlPath += `&estadoContestacion=${filters.estadoContestacion}`;
  }
  if (filters && filters.estadoLeido && filters.estadoLeido.length > 0) {
    urlPath += `&estadoLeido=${filters.estadoLeido}`;
  }
  if (localStorage.getItem("agrupacionClienteSelected")) {
    urlPath += `&agrupacion=${localStorage.getItem(
      "agrupacionClienteSelected"
    )}`;
  }
  if (filters && filters.prioridad && filters.prioridad.length > 0) {
    urlPath += `&prioridad=${filters.prioridad.join()}`;
  }
  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.origen && filters.origen.length > 0) {
    urlPath += `&origen=${filters.origen.join()}`;
  }
  if (filters && filters.usuario && filters.usuario) {
    urlPath += `&usuarioResponsable=${filters.usuario}`;
  }
  /* if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`
  } else {
    urlPath += `&sort=sla.fechaResolucion&order=ascending`
  } */
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.imputacion) {
    urlPath += `&imputacion=${filters.imputacion}`;
  }
  if (filters && filters.descripcion) {
    urlPath += `&descripcion=${filters.descripcion}`;
  }
  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }
  if (filters && filters.ocurrencia !== "") {
    urlPath += `&ocurrencia=${filters.ocurrencia}`;
  }
  return urlPath;
}

function getIncidencias(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencias`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFiltersIncidencias(filters);

  return axios.get(urlPath);
}

function getIncidenciaById(idIncidencia) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}`;
  return axios.get(urlPath);
}

function getSolucionesByIncidencia(idIncidencia) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}/soluciones`;
  return axios.get(urlPath);
}

function getIncidenciaChats(idIncidencia, pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}/chat`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.sort}&order=${filters.sort.order}`;
  }
  return axios.get(urlPath);
}

function patchIncidencia(idIncidencia, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}`;
  return axios.put(urlPath, body);
}

function multipleAsignarIncidencia(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencias/control`;
  return axios.put(urlPath, body);
}

function postMensaje(idIncidencia, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}/chat`;
  return axios.post(urlPath, body);
}

function deleteChatIncidencia(idIncidencia, idChat) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}/chat/${idChat}`;
  return axios.delete(urlPath);
}

function putChatIncidencia(idIncidencia, idChat, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}/chat/${idChat}`;
  return axios.put(urlPath, body);
}

function getAdjunto(idIncidencia, idChat) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/incidencia/${idIncidencia}/chat/${idChat}/adjunto`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}
