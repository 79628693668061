import createAxios from "./axiosHttpMarconi";

export const tarifaBaseService = {
  getTarifaById,
  getTarifas,
  getTarifasAsExcel,
  getTarifasGeos,
};

function getTarifaById(id) {
  const axios = createAxios();
  const franquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `franquicia/${franquicia._id}/tarifa_base/${id}`;
  return axios.get(urlPath);
}

function getTarifas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/tarifas_base`;
  if (pagination) {
    urlPath += `?sort=desde&order=ascending&page=${
      pagination.currentPage - 1
    }&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters);
  return axios.get(urlPath);
}

function getTarifasGeos() {
  const axios = createAxios();
  const franquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `franquiciadora/${franquicia._id}/tarifa_base/geos`;
  return axios.get(urlPath);
}

function getTarifasAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/tarifas_base/excel?limit=2000`;
  urlPath += getFilters(filters);
  urlPath += "&sort=desde&order=descending";
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.date) {
    urlPath += `&desde=${filters.date[0]}`;
    urlPath += `&hasta=${filters.date[1]}`;
  }
  if (filters && filters.codigoTarifa) {
    urlPath += `&codigo=${filters.codigoTarifa}`;
  }
  if (filters && filters.descripcion) {
    urlPath += `&descripcion=${filters.descripcion}`;
  }
  if (filters && filters.servicio) {
    urlPath += `&servicio=${filters.servicio}`;
  }
  if (filters && filters.geografico) {
    urlPath += `&geografico=${filters.geografico}`;
  }
  urlPath += `&vigente=${filters.vigente}`;
  return urlPath;
}
